import { Scene, PerspectiveCamera, WebGLRenderer, AmbientLight, HemisphereLight, Color } from "three"
import { cameraProps } from "variables/objects"
import { InteractionManager } from "three.interactive"
import { HandleKeyDown, HandleKeyUp, HandleMouseDown, HandleOrient, OnWindowResize } from "./handlers"
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js"
import { MovementProps } from "variables/objects"

export function InitEnv(SetEnv, ThrowException, location) {
    let scene = new Scene()
    let camera = InitCamera(location)
    let renderer = InitRenderer()
    let interaction = new InteractionManager(
        renderer,
        camera,
        renderer.domElement
    )
    let lightings = InitLighting(location)
    lightings.forEach((element) => {
        scene.add(element)
    })
    let movement = MovementProps
    let prevTime = performance.now()
    document.body.appendChild(renderer.domElement)
    window.addEventListener("resize", () => OnWindowResize(camera, renderer), false)
    if (window.innerWidth > window.innerHeight) {
        document.addEventListener("keydown", (event) => HandleKeyDown(event, movement))
        document.addEventListener("keyup", (event) => HandleKeyUp(event, movement))
    }
    else {
        window.addEventListener("deviceorientation", (event) => HandleOrient (event, movement), true)
        document.addEventListener("mousedown", (event) => HandleMouseDown(event, movement))
    }
    let control = new PointerLockControls(camera, renderer.domElement)
    scene.add(control.getObject())
    SetEnv({
        scene,
        camera,
        renderer,
        interaction,
        prevTime,
        movement,
        control
    })
}

function InitLighting(location) {
    let lightings = []
    if (location == "lobby") {
        let hemiLight = new HemisphereLight(0xf7e6ff, 0xffffff, 1.2)
        hemiLight.position.set( -19, 5, 22 )
        hemiLight.castShadow = true
        lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xdd99ff, 0.2)
        lightings.push(ambientLight)
    }
    if (location == "auditorium") {
        let hemiLight = new HemisphereLight(0xffffff, 0xffffff, 1.2)
        hemiLight.position.set( -19, 5, 22 )
        hemiLight.castShadow = true
        lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 0.2)
        lightings.push(ambientLight)
    }
    if (location == "hackroom") {
        let hemiLight = new HemisphereLight(0xffffff, 0xffffff, 1.2)
        hemiLight.position.set( -19, 5, 22 )
        hemiLight.castShadow = true
        lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 0.2)
        lightings.push(ambientLight)
    }
    if (location == "gameroom") {
        let hemiLight = new HemisphereLight(0x9d88eb, 0x6694ff, 3)
        hemiLight.position.set( -19, 5, 22 )
        hemiLight.castShadow = true
        lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 2)
        lightings.push(ambientLight)
    }

    return lightings
}

function InitCamera(location) {
    let camera = new PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        1000
    )
    let {position, rotate} = cameraProps[location]
    camera.position.x = position.x
    camera.position.y = position.y
    camera.position.z = position.z
    camera.rotateX(rotate.x)
    camera.rotateY(rotate.y)
    camera.rotateZ(rotate.z)
    return camera
}

function InitRenderer() {
    let renderer = new WebGLRenderer({
        antialias: true,
        alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(new Color(0x061D37), 0.8)

    return renderer
}