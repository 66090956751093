import { Col, Container, Row } from "reactstrap"
import video1 from "./../assets/video/Community App.mp4"
import video2 from "./../assets/video/Delivery App.mp4"
import video3 from "./../assets/video/Event Planning.mp4"
import video4 from "./../assets/video/Guarantee Letter App.mp4"
import video5 from "./../assets/video/IPMS.mp4"
import video6 from "./../assets/video/Longi.mp4"
import video7 from "./../assets/video/patient handbook.mp4"
import video8 from "./../assets/video/Shopping.mp4"
import video9 from "./../assets/video/home intrusion.mp4"
import video10 from "./../assets/video/enterprise.mp4"
import video11 from "./../assets/video/logistics.mp4"
import video12 from "./../assets/video/parking.mp4"
import video13 from "./../assets/video/car security.mp4"
import video14 from "./../assets/video/assets.mp4"
import video15 from "./../assets/video/reservation.mp4"
import video16 from "./../assets/video/fruit pos.mp4"
import video17 from "./../assets/video/fruit sensor.mp4"
import Logo from "assets/img/logo.png";
import { Link } from "react-router-dom"

export default function Videos() {
    const data = [
        {
            title: "Community App",
            video: video1,
            description: "I am representing the community and I would like to suggest a mobile app that can provide us with alerts on flash floods, disruption of utility services and crime through crowd sourced IoT data and alerts submitted by users. There are implementation of IoT flood detection systems by the local government and private institutions on separate platforms. However these data are not made known to the public.I would like to suggest an affordable IoT for flood detection that can be installed by the community and can be easily connected to the mobile app upon registration of the device on the mobile app and submission of its location. This will allow communities and the council to install it in residential areas and roads to provide warning of flood. Apart from that, the system should have open API to allow government and private institutions to connect to it. In addition, I would like to suggest the mobile app to be able to let the public post tipoffs on utility disruption based on their current phone GPS location and crime. The system needs to prevent the tipoff from any other location other than the user's actual location to avoid fradulent tipoffs. The tipoffs should be labeled as a \"Public tipoff\" to let users decide on the authenticity of the tipoff on their own. Mobile app users should be able to set alarms on the tipoffs they would like to get from the IoT such as flood detection, disruption of utility services and crime based on the location they enter."
        },
        {
            title: "Food Delivery App",
            video: video2,
            description: "DHAUSE has vendors who are currently selling food and drinks on our EzMarket platform https://dhause.com/ezmarket-vendors. Some of these vendors are selling the products on their own website which is mostly built from WordPress. We would like to solve this issue by creating an open source delivery app which is able to connect to WordPress and PHP websites. This delivery app should be a simple application whereby anyone who is registered with the mobile app and placed a minimum deposit (for example RM 30) into their wallet is able to help pick up the product and deliver and earn some money upon delivering it for a fellow customer based on a first claim basis. This mobile app will be able to tap into not just the rider market which is common in Grab and Food Panda but for lay customers who are picking up their own food and maybe able to deliver and earn some spare income. The wallet later will also be integrated into our EzMarket plaform where it can also be used for the riders to purchase food."
        },
        {
            title: "Event Materials Inventory",
            video: video3,
            description: "Hi everyone, my name is Melvin. I'm from an event planning company. One of the problems that we always face is keeping track of our inventory of event materials. Often times than not, when we are in a peak season, there isn't enough time for a thorough inventory check after every event. When proper checking wasn't done, we have lost a few assets previously and not in time to replace the missing component. To tackle with this, we have ordered more materials to meet the season's demands. However, this resolution has also led to some wastage on materials with short lifespan and in usual times, most of the inventories are left unused. It will be great if there is a technology developed that allows us to update inventory within minutes so we can better handle our inventory. Thank you"
        },
        {
            title: "Guarantee Letter App",
            video: video4,
            description: "I am representing a clinic who has been appointed by companies as their panel clinic for their employees. Currently, I will need to check a physical guarantee letter or call the staff office to verify if the person is indeed an employee of the company and the limit of their claims. I would like a system that can digitise this whereby companies who issue Guarantee Letters can upload a database of the employees and their claim limit and assign or invite panel clinics to their account. The panel clinics who are not registered  will need to sign up for an account and accept the invitation as the company's panel clinic to have access to a search system that can verify the person. The employees of the company will also be invited to open an account and download an app which generates a unique QR code which the clinic can scan to validate. The clinc will also be able to see the claim limits for the employee as well as enter the total amount of medical fees spent with an option to attach a picture of the receipt for the company to view. The company admin will then be notified of the claims and will be able to tally the claims with the monthly invoicing from the clinic. This application will smoothen the process for hospitals and clinics in verifying employee benefits and at the same time reduce claims paperwork for the company employee and HR."
        },
        {
            title: "IPMS Community App",
            video: video5,
            description: "Hi, I'm representing the IPMS Property Management software and we would like to have a mobile app that can be used by the community for free to promote our property mangement solution to the property managers. The mobile app should have features that can be monetized for example the fundraising events feature and any other features the Hackathon participants can think of. Many people move into a neighborhood or live in a neighborhood without knowing their neighbours and fellow dwellers in the apartment or condominium. Because of this, there is lack of an interaction between the neighbors and they are not looking out for one another because they are strangers. There will be difficulties in reaching out the neighbours for condo/neighborhood activities such as Neighborhood Crime Patrol and other activities. The Malaysian community is generally quite reserved and we would like a solution that will allow communities to find others within their neighbourhood, view each other's short profile, connect in community forum, organise activities and fundraise without revealing the participant's phone number and exact unit where they are staying to protect their privacy. This mobile app must also have security features which requires users to validate their place of residence and in pinning their GPS location to avoid fraud and to indicate when they have moved. This mobile app can later be integrated into our Property Management system which is ipms.asia."
        },
        {
            title: "Tracking of Odd Clock in eAttendance for Employees",
            video: video6,
            description: ""
        },
        {
            title: "Patient's Handbook",
            video: video7,
            description: "I am representing a gynecologist who is having a dilema from many different clinical record book from pregnant mothers and mothers who have delivered. This clinical record book is commonly known as the \"pink book\". This handbook also does not have details on a baby's immunisation as the immunisation can be done at different clinics and is not recorded into a book. I would like the team to create a mobile app for digitalising this clinical record book that can be used at all hospitals. Each new mother would be able to download the mobile app and sign up for an account. The mothers will be able to provide a unique QR code from the app for doctors to scan with a clinic mobile phone and be provided temporary access to their records and to enter new records. The mothers will have a push notification on their phone to grant access to the doctor once the QR code has been signed. Doctors will need to provide their name and their license number upon creating the new record. The name and license will need to match with the Medical Register Information and Technial System (MeRITS) before the entry can be made. During the course of this hackathon, the teams do not have to connect to the MeRITS system but the system can be created and demonstrated based on this assumption. In this way, doctors will not need to register and the system is purely optional for the pregnant mothers who opt for this convenience. This system can be adapted for other medical handbooks for heart patients at a later stage."
        },
        {
            title: "Shopping Companion",
            video: video8,
            description: "Hi, I’m looking for a smart shopping companion. I would like it if my smart companion is able to aid me in manually counting the items that I have bought. Sometimes while shopping in real life, we may lose track of the total price of items while adding it to our shopping cart blindly. Hence, this is where my smart companion helps me with the calculation of the overall price in cart as at times, the price tag of the products may not be present or the price scanner is nowhere to be found. At certain times as well, there might be ongoing promotions for certain products and I am not too sure if the items I’ve added to my cart meets on-going promotion requirements. My last preference for my shopping companion would be helping me, an indecisive and impulsive buyer to make decisions of purchasing the products in my cart or the location where I can locate the products. It would be great if my shopping companion can provide me the price comparison for shopping places nearby, and even recommend me similar items that I can get when the product I'm looking for isn't available anywhere."
        },
        {
            title: "IoT Theft Detection For Smart Home",
            video: video9,
            description: "I am representing a company who does traditional locksets for our customers. I would like to expand our line of products into digital products by creating a sensor for home intrusions. This sensor should be able to connect to the internet within the house and send alerts when the doors and windows are opened during a time period set by the homeowner on the app settings. The mobile app should be able to alert the users in the form of push notification. The requirement for this sensor is that it should be small and inexpensive and runs on battery. It should be able to attach to doors and windows easily and the battery should last for at least 3-6 months and will provide the users with an indication to replace them on the mobile app."
        },
        {
            title: "Enterprise Operations App",
            video: video10,
            description: "Covid 19 has caused lockdowns all over Malaysia and with unpredictable quarantines required from staffs who are Covid-19 positive or are close contacts. Our company would like to have a solution of an enterprise mobile app for our employees to clock-in and clock-out while working either in the office or remotely. Previously we were using an online clock-in app which had issues with employees clocking in on the way to the office without us being able to validate the location. This new solution should be able to geotag the employees location when they are clocking in and check-in on intervals on their location to ensure a \"real\" clock-in. As for monitoring work from home, we would like an application to be able to stream an employee's work screen (similar to team viewer) so the supervisor is able to monitor work progress, or any other suggestions on monitoring work progress from the teams. We are also looking for features for building team morale such as gamifying and rewarding those who clock in early or have accomplished their tasks on time, which will probably need a task tracker with employee reward points. Leading employees should be shown on a leaderboard with the points they have accumulated."
        },
        {
            title: "Logistics Tracking App",
            video: video11,
            description: "I am representing a logistics company. Managing a fleet of trucks is challenging and while there are digital solutions available in the market, they are very costly to implement as they require investment in hardware and a system. We are looking for a cost-effective mobile application to allow the tracking of the truck via an app on the driver’s mobile device. The driver will check-in on the app in their mobile devices that would track the truck and driver via GPS. We also would need a historical display of the route that is taken with a timestamp. The app will thus allow the company to track the location of the truck in real time and review historical records if there are incidences that require clarification of where the driver/truck is at a certain time. Other features that would be useful is allowing the driver to generate a log entry where the date, time and location is captured. This is so that the driver can file an incidence report to justify delays, change of routes or breakdown of vehicle."

        },
        {
            title: "Location of car in carpark using LPR",
            video: video12,
            description: "I am representing a shopping mall management company that is looking at ways to improve the security as well as giving mall visitors a better shopping experience while leveraging on modern technology to support our small team of security and administration personnel. We have recently replaced our parking ticketing machines with the LPR parking system and are looking at ways to further build on the digital technology foundation we now have. We have had cases where our mall visitors have parked their cars but have forgotten where they parked their cars thus requiring assistance of our security to locate their cars. We feel that we can deliver a better shopping experience if we are able to assist our visitors in locating their cars in a rapid manner without needing to deploy many security personnel and taking the time of these personnel. I would like the team to leverage on our LPR camera systems and our cctv cameras in the carparks to allow mall visitors to find their cars in our carparks. We would like a solution where the security personnel could access a touch screen to locate the misplaced car by keying in the car plate number and having the car’s location displayed. Furthermore, we would also like a security precaution to prevent incidences where it is not the car owner asking for the information by ensuring that we are able to verify it is the car owner by asking them to tell the car entry timeframe in which they entered the car park."
        },
        {
            title: "Car Security",
            video: video13,
            description: "I am concerned about the security of my car thus I am seeking an IoT solution to help me. My main concern is that my car may be broken into and I am unable to react in a swift and appropriate manner. While my car has an alarm system, it is useless as I may be too far away or may not realise that my car is being broken into. I would like a solution to immediately alert me when there has been an intrusion to my car. It would be most helpful if the solution can also show if my car locks are locked or unlock and give me a reminder to lock my car if its unlocked."
        },
        {
            title: "Assets Tracking",
            video: video14,
            description: "Company assets are important for the daily operations. Some companies may have inventory and stock management systems to track their consumables or items for sale but may not have asset inventory and tracking solutions. In this case, companies often face challenges in tracking the physical location and status of assets resulting in being neglected, abused, lost or stolen. In larger organizations with sudsidiaries or branches, the assets may be jumbled and thus making it difficult to identify the asset's owner and its location. These assets includes hardware, office furnitures, equipment such as computers, cameras, printers, etc. and tools. In the light of such risks, we would like to be able to have an application where we can register the assets and print a QR for each items. The application should also be able to register assets into the registry by scanning a QR attached to the item. We also hope that the application can allow us to save the image(s) of the asset, to ease identification and record the condition of the asset at the moment registration. Besides that, we will also need to identify the location of each asset whether it's within or out of office premises. Hence, having a location tagging feature for registration would be helpful. Each update on the asset should be kept as a history in order for future allocation.."
        },
        {
            title: "Real Time Table Reservation",
            video: video15,
            description: "I am representing a company in the Hospitality and Food and Beverages Industry. As a result of COVID19, we have been imposed dine-in restrictions by the government, of which a major restriction is the reduction in the maximum capacity of guests in our restaurant. Furthermore, there has been a decrease in customers due to competition from food delivery services. For dine-in F&B outlets, the challenge is to deliver a better customer experience while reducing our costs such as that in human resources. We hope that a digital solution could help us achieve that. One challenge we face is in managing reservations which are usually done manually. Due to the restrictions in maximum capacity, we have to utilize our tables fully thus we have to be able to plan and implement our table utilization to allow for maximum use. We cannot have cases of left out reservations and double booking.  A such, we would like the team to look in a solution of real time table utilisation with number of pax which is integrated with online table reservations with number of pax. The solution should also indicate a time frame component so we are able to do planning and allocation of our resources. The solution will have to do table mapping of our outlet as well as to show total number of pax which may be variable as maximum capacity may change due to legal requirements."
        },
        {
            title: "Fruit Distributor POS",
            video: video16,
            description: "I am representing the owner of a large fruits distribution company that supplies stock to supermarkets. As a fruit distribution company I will need to physically check-in to the supermarkets to see our current stocks left on display. As the products are on consignment, we can replenish our friuts when they run out. However, we are often not notified by the supermarkets when the fruits get sold out and we lose the opportunity to sell more. Having a system that allows us to view our inventory left at the supermarket will allow us to stock up and greatly increase our sales. I would like a Point of Sale system that has a mobile app for vendors to be able to monitor their stock on the mobile phone as well as do price adjustments when there's a lot of stock left over. The supermarkets usually take a percentage of the price that we sell so they are able to keep their profile margin and this solution will allow us to take a lower profit and reduce our losses with spoiled fruits. The supermarkets will be able to introduce this point of sale system and provide access to the mobile app to all vendors. I hope the teams can come up with a solution for this that will assist fruit distributors and farmers who sell to supermarkets."
        },
        {
            title: "Smart Fruits Sensor",
            video: video17,
            description: "I am representing the owner of a large fruits distribution company that supplies stock to supermarkets. Usually when our fruits arrive at the factory we will transfer our fruits to a basket which we then place onto a pallet and move the pallet with a forklift to a weighing machine. This process is tedious and time consuming. I would like to request the teams to create an IoT that will be placed at the bottom of the baskets for us to measure the weight of the fruits. This should be doable with a pressure sensor that is connected to a mobile app that will allow us to calibrate to the actual weight of the basket which we can then use as a reference point for weighing the other baskets. We should also be able to set an estimated weight of one fruit to estimate the total number of fruits in the basket. This will help us estimate the number of fruits we will need to purchase from the local farmers in order to fulfill our local and export orders. This pressure sensor IoT must be compact and easily placed at the base of the baskets before we load the fruits and the app should be able to provide a record of all the measurements which we can select to tally."
        }
    ]
    let contents = data.map(element => {
        return (
            <Row>
                <Col xs={12} md={6} lg={5} className="pb-5">
                    <p>{element.title}</p>
                    <video src={element.video} controls />
                </Col>
                <Col xs={12} md={6} lg={7} className="py-3">
                    <p>
                        {element.description}
                    </p>
                </Col>
            </Row>
        )
    })
    return (
        <Container className="scrollable">
            <Row>
                <Col xs={12} className="mt-4">
                    <Link to="/" className="text-white">Back</Link>
                </Col>
            </Row>
            <p className="text-center mt-5 mb-3">
                <img src={Logo} className="mb-5" width="30%" />
            </p>
            <h1 className="h1-seo text-center">Problem Statements</h1>
            {contents}
        </Container>
    )
}