import lobby from "../assets/models/lobby2.gltf"
import auditorium from "../assets/models/auditorium.gltf"
import hackroom from "../assets/models/hack_room.gltf"
import gameroom from "../assets/models/game_room.gltf"
import screen from "../assets/models/event_screen.gltf"
import schedule from "../assets/models/schedule.gltf"
import techtalk from "../assets/models/techtalk.gltf"
import dance from "../assets/models/dance.gltf"
import dance2 from "../assets/models/dance2.gltf"
import racer from "../assets/models/racer.gltf"
import racer2 from "../assets/models/racer2.gltf"
import game from "../assets/models/game.gltf"
import helvetiker from "../assets/fonts/helvetiker_bold.typeface.json"
import karuna from "../assets/img/karuna.jpg"
import sdec from "../assets/img/sdec.jpg"
import mdec from "../assets/img/mdec.jpg"
import techtalkposter from "../assets/img/techtalk.jpg"
import info from "../assets/img/info.png"
import problem from "../assets/img/problem.png"
import enter from "../assets/img/enter.png"
import intro from "../assets/img/Briefing.jpg"
import deck from "../assets/img/deck.jpg"
import businesscaseposter from "../assets/img/businesscase.jpg"
import iotposter from "../assets/img/Tech Talk Displays-01.jpg"
import securityposter from "../assets/img/Tech Talk Displays-02.jpg"
import flutterposter from "../assets/img/Tech Talk Displays-03.jpg"
import trendsposter from "../assets/img/Tech Talk Displays-04.jpg"
import { MeshPhongMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { iframeContent, imgContent } from "helpers/modal"
const fontLoader = new FontLoader()
export const playerProps = {
    mass: 100.0,
    height: 4
}
export const cameraProps = {
    lobby: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 0
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    auditorium: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 0
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    hackroom: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 0
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        }
    },
    gameroom: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 0
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        }
    }
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	}
}

export const models = {
    lobby: {
        structure: lobby,
        screen,
        schedule,
        techtalk,
    },
    auditorium: {
        structure: auditorium,
    },
    hackroom: {
        structure: hackroom,
    },
    gameroom: {
        structure: gameroom,
        zumba: dance,
        kpop: dance2,
        typing: racer,
        charades: game,
        tetris: racer2
    }
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    lobby: {
        left_screen_plane: {
            path: mdec,
            repeatX: 1,
            repeatY: 1
        },
        middle_screen_plane: {
            path: karuna,
            repeatX: 1,
            repeatY: 1
        },
        right_screen_plane: {
            path: sdec,
            repeatX: 1,
            repeatY: 1
        },
        reception_info_cylinder: {
            path: info,
            repeatX: 1,
            repeatY: 1
        },
        hack_room_cylinder: {
            path: enter,
            repeatX: 1,
            repeatY: 1
        },
        game_room_cylinder: {
            path: enter,
            repeatX: 1,
            repeatY: 1
        },
        auditorium_cylinder: {
            path: enter,
            repeatX: 1,
            repeatY: 1
        },
        exhibition_hall_cylinder: {
            path: enter,
            repeatX: 1,
            repeatY: 1
        }
    },
    auditorium: {
        business_case_plane: {
            path: businesscaseposter,
            repeatX: 1,
            repeatY: 1
        },
        iot_plane: {
            path: iotposter,
            repeatX: 1,
            repeatY: 1
        },
        trends_plane: {
            path: trendsposter,
            repeatX: 1,
            repeatY: 1
        },
        security_plane: {
            path: securityposter,
            repeatX: 1,
            repeatY: 1
        },
        flutter_plane: {
            path: flutterposter,
            repeatX: 1,
            repeatY: 1
        },
    },
    hackroom: {
        problem_statement_button_cylinder: {
            path: problem,
            repeatX: 1,
            repeatY: 1
        },
        intro_plane: {
            path: intro,
            repeatX: 1,
            repeatY: 1
        },
        deck_plane: {
            path: deck,
            repeatX: 1,
            repeatY: 1
        },
    },
    gameroom: {
    }
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value,
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    lobby: {
        left_door: {
            geometry: "text",
            text: "Auditorium",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 0.8,
                height: 0.1,
            },
            material:  new MeshPhongMaterial({
                color: 0xffffff,
                specular: 0xfffaef,
                shininess: 500,
                flatShading: true
            })
        },
        right_door: {
            geometry: "text",
            text: "Exhibition Hall",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 0.8,
                height: 0.1,
            },
            material:  new MeshPhongMaterial({
                color: 0xffffff,
                specular: 0xfffaef,
                shininess: 500,
                flatShading: true
            })
        },
        left_side_door: {
            geometry: "text",
            text: ` Hack\nRoom`,
            params: {
                font: fontLoader.parse(helvetiker),
                size: 0.5,
                height: 0.1,
            },
            material:  new MeshPhongMaterial({
                color: 0xffffff,
                specular: 0xfffaef,
                shininess: 500,
                flatShading: true
            })
        },
        right_side_door: {
            geometry: "text",
            text: `Game\nRoom`,
            params: {
                font: fontLoader.parse(helvetiker),
                size: 0.5,
                height: 0.1,
            },
            material:  new MeshPhongMaterial({
                color: 0xffffff,
                specular: 0xfffaef,
                shininess: 500,
                flatShading: true
            })
        },
        left_screen: {
            geometry: "plane",
            width: 16,
            height: 9,
            material: null
        },
        middle_screen: {
            geometry: "plane",
            width: 16,
            height: 9,
            material: null
        },
        right_screen: {
            geometry: "plane",
            width: 16,
            height: 9,
            material: null
        },
        reception_info: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        hack_room: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        exhibition_hall: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        auditorium: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        game_room: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        date: {
            geometry: "text",
            text: "19-21, 24 December",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 0.2,
                height: 0.1,
            },
            material:  new MeshPhongMaterial({
                color: 0xffffff,
                specular: 0xfffaef,
                shininess: 500,
                flatShading: true
            })
        },
    },
    auditorium: {
        business_case: {
            geometry: "plane",
            width: 415,
            height: 623,
            material: null
        },
        iot: {
            geometry: "plane",
            width: 415,
            height: 623,
            material: null
        },
        trends: {
            geometry: "plane",
            width: 415,
            height: 623,
            material: null
        },
        flutter: {
            geometry: "plane",
            width: 415,
            height: 623,
            material: null
        },
        security: {
            geometry: "plane",
            width: 415,
            height: 623,
            material: null
        },
    },
    hackroom: {
        problem_statement: {
            geometry: "text",
            text: "Problem\nStatements",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 0.2,
                height: 0.1,
            },
            material:  new MeshPhongMaterial({
                color: 0xff6600,
                specular: 0xfffaef,
                shininess: 500,
                flatShading: true
            })
        },
        problem_statement_button: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        intro: {
            geometry: "plane",
            width: 623,
            height: 415,
            material: null
        },
        deck: {
            geometry: "plane",
            width: 623,
            height: 415,
            material: null
        },
    },
    gameroom: {
    }
}

export const lobbyProps = {
    structure: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -17,
            y: 0,
            z: 25
        }
    },
    screen: {
        scale: {
            x: 11,
            y: 11,
            z: 11
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -12,
            y: 0.4,
            z: -2
        }
    },
    schedule: {
        scale: {
            x: 2,
            y: 2,
            z: 2
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -20,
            y: 1,
            z: -2
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: iframeContent("https://www.dropbox.com/s/v51t7hbglobxs43/schedule.pdf?raw=1"),
                title: "Event Schedule"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    techtalk: {
        scale: {
            x: 2,
            y: 2,
            z: 2
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -20,
            y: 1,
            z: -3
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(techtalkposter),
                title: "Tech Talks"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // briefing: {
    //     scale: {
    //         x: 2,
    //         y: 2,
    //         z: 2
    //     },
    //     rotate: {
    //         x: 0,
    //         y: Math.PI * -0.5,
    //         z: 0
    //     },
    //     position: {
    //         x: 13.4,
    //         y: 2,
    //         z: -9
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: videoContent(briefingvideo),
    //             title: "Participants Briefing"
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    left_booth: {
        scale: {
            x: 1,
            y: 1.5,
            z: 1.5
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -14,
            y: 1,
            z: 1
        }
    },
    right_booth: {
        scale: {
            x: 1.5,
            y: 1.5,
            z: 2
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 13,
            y: 1,
            z: -10
        }
    },
    left_door_text: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -9,
            y: 5,
            z: -21
        }
    },
    right_door_text: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 4.8,
            y: 5,
            z: -21
        }
    },
    left_side_door_text: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -12,
            y: 5.3,
            z: -18.5
        }
    },
    right_side_door_text: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI * -0.5,
            z: 0
        },
        position: {
            x: 13.7,
            y: 5.3,
            z: -20.3
        }
    },
    left_screen_plane: {
        scale: {
            x: 0.35,
            y: 0.35,
            z: 0.35
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.01,
            z: 0
        },
        position: {
            x: -6.2,
            y: 8.5,
            z: -21.5
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://mdec.my/"),
            },
            {
                action: "mouseover",
                handler: () => document.body.style.cursor = "pointer"
            },
            {
                action: "mouseout",
                handler: () => document.body.style.cursor = "default"
            }
        ]
    },
    middle_screen_plane: {
        scale: {
            x: 0.35,
            y: 0.35,
            z: 0.35
        },
        rotate: {
            x: Math.PI * 0.03,
            y: Math.PI * 0.01,
            z: 0
        },
        position: {
            x: 1,
            y: 8,
            z: -21
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://karunasarawak.com/"),
            },
            {
                action: "mouseover",
                handler: () => document.body.style.cursor = "pointer"
            },
            {
                action: "mouseout",
                handler: () => document.body.style.cursor = "default"
            }
        ]
    },
    right_screen_plane: {
        scale: {
            x: 0.35,
            y: 0.35,
            z: 0.35
        },
        rotate: {
            x: Math.PI * 0.01,
            y: 0,
            z: 0
        },
        position: {
            x: 8.5,
            y: 8.5,
            z: -21.5
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://sdec.com.my/web/"),
            },
            {
                action: "mouseover",
                handler: () => document.body.style.cursor = "pointer"
            },
            {
                action: "mouseout",
                handler: () => document.body.style.cursor = "default"
            }
        ]
    },
    reception_info_cylinder: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 1,
            y: 3,
            z: -20
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.01,
                y: Math.PI * 0.01,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("http://www.wasap.my/60162514983/"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    game_room_cylinder: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 13,
            y: 2,
            z: -19
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.01,
                y: Math.PI * 0.01,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/game",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    hack_room_cylinder: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -11,
            y: 2,
            z: -19
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.01,
                y: Math.PI * 0.01,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/hack",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    auditorium_cylinder: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -6,
            y: 2,
            z: -20
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.01,
                y: Math.PI * 0.01,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/auditorium",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    exhibition_hall_cylinder: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 7.5,
            y: 2,
            z: -20
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.01,
                y: Math.PI * 0.01,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://virtualfair.myhackwknd.com/"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    date_text: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -12,
            y: 4.5,
            z: -10
        }
    },
}

export const auditoriumProps = {
    structure: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -100,
            y: -2,
            z: 38
        }
    },
    business_case_plane: {
        scale: {
            x: 0.005,
            y: 0.005,
            z: 0.005
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.4,
            z: 0
        },
        position: {
            x: -5,
            y: 4,
            z: -10
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/watch/businesscase"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    iot_plane: {
        scale: {
            x: 0.005,
            y: 0.005,
            z: 0.005
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.2,
            z: 0
        },
        position: {
            x: -2.5,
            y: 4,
            z: -13
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/watch/iot"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    security_plane: {
        scale: {
            x: 0.005,
            y: 0.005,
            z: 0.005
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1,
            y: 4,
            z: -14
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/watch/security"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    flutter_plane: {
        scale: {
            x: 0.005,
            y: 0.005,
            z: 0.005
        },
        rotate: {
            x: 0,
            y: Math.PI * -0.4,
            z: 0
        },
        position: {
            x: 7,
            y: 4,
            z: -10
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/watch/flutter"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    trends_plane: {
        scale: {
            x: 0.005,
            y: 0.005,
            z: 0.005
        },
        rotate: {
            x: 0,
            y: Math.PI * -0.2,
            z: 0
        },
        position: {
            x: 4.5,
            y: 4,
            z: -13
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/watch/trends"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}

export const hackroomProps = {
    structure: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5,
            y: 2.5,
            z: 0
        }
    },
    problem_statement_button_cylinder: {
        scale: {
            x: 0.03,
            y: 0.03,
            z: 0.03
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: Math.PI * 0.5
        },
        position: {
            x: -4,
            y: 4,
            z: -5
        },
        animation: {
            animation: "rotate",
            value: {
                x: 0,
                y: Math.PI * 0.01,
                z: Math.PI * 0.01
            }
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/problemstatements",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    problem_statement_text: {
        scale: {
            x: 0.5,
            y: 0.5,
            z: 0.5
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.3,
            z: 0
        },
        position: {
            x: -4.2,
            y: 4.5,
            z: -4.7
        }
    },
    intro_plane: {
        scale: {
            x: 0.005,
            y: 0.005,
            z: 0.005
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.1,
            z: 0
        },
        position: {
            x: -2,
            y: 4.5,
            z: -11
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/watch/briefing",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    deck_plane: {
        scale: {
            x: 0.003,
            y: 0.003,
            z: 0.003
        },
        rotate: {
            x: 0,
            y: Math.PI * -0.1,
            z: 0
        },
        position: {
            x: 1,
            y: 4.5,
            z: -8
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: iframeContent("../assets/files/Karuna Hackwknd 2021 Participant Guidelines.pdf"),
                title: "Participants Guidelines"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    }
}

export const gameroomProps = {
    structure: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -10,
            y: 1.5,
            z: 4
        }
    },
    zumba: {
        scale: {
            x: 1.5,
            y: 1.5,
            z: 1.5
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 4,
            y: 2.7,
            z: -18
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://us02web.zoom.us/j/84013877279?pwd=K3N6TjdPaEtEWklJOFRVZ2xWeHRZdz09"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    kpop: {
        scale: {
            x: 1.5,
            y: 1.5,
            z: 1.5
        },
        rotate: {
            x: 0,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -1,
            y: 2.7,
            z: -18
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://us02web.zoom.us/j/83534043457?pwd=dWhnSDZtMGZGTEtrdEh0SVgxREtnUT09"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    typing: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI * -0.3,
            z: 0
        },
        position: {
            x: -8,
            y: 2.7,
            z: -15
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://typer.io/"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    charades: {
        scale: {
            x: 1,
            y: 1.5,
            z: 1.5
        },
        rotate: {
            x: 0,
            y: Math.PI * -0.5,
            z: 0
        },
        position: {
            x: 6,
            y: 2.7,
            z: -14
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://us02web.zoom.us/j/88040197368?pwd=ZDVSMHphTDJQS1Fjd2poNDJmSisyZz09"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    tetris: {
        scale: {
            x: 0.06,
            y: 0.06,
            z: 0.06
        },
        rotate: {
            x: 0,
            y: Math.PI * -1.3,
            z: 0
        },
        position: {
            x: -6,
            y: 2.7,
            z: -8
        },
        interactions: [
            {
                action: "click",
                handler: () => window.open("https://tetr.io/#TLAF"),
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    }
}
