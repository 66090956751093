import up from "../assets/img/up.png"
import down from "../assets/img/down.png"
import left from "../assets/img/left.png"
import right from "../assets/img/right.png"
import w from "../assets/img/w.png"
import a from "../assets/img/a.png"
import s from "../assets/img/s.png"
import d from "../assets/img/d.png"
import click from "../assets/img/d.png"
import mleft from "../assets/img/mleft.png"
import mright from "../assets/img/mright.png"
import mfront from "../assets/img/mfront.png"
import mback from "../assets/img/mback.png"
import iosleft from "../assets/img/iosleft.png"
import iosright from "../assets/img/iosright.png"
import iosfront from "../assets/img/iosfront.png"
import iosback from "../assets/img/iosback.png"
import { isIOS } from "react-device-detect"

export function iframeContent(url) {
    return (
        <div>
            <iframe src={url}/>
        </div>
    )
}

export function imgContent(resource) {
    return (
        <img src={resource} width="100%" />
    )
}

export function videoContent(resource) {
    return (
        <video src={resource} controls />
    )
}

const pcInstructions = (
    <>
        <h2>PC Controls</h2>
        <table className="instructions-table">
            <tr>
                <td>
                <img src={up} />
                or
                <img src={w} />
                </td>
                <td>Move forward</td>
            </tr>
            <tr>
                <td>
                <img src={down} />
                or
                <img src={s} />
                </td>
                <td>Move backward</td>
            </tr>
            <tr>
                <td>
                <img src={left} />
                or
                <img src={a} />
                </td>
                <td>Turn left</td>
            </tr>
            <tr>
                <td>
                <img src={right} />
                or
                <img src={d} />
                </td>
                <td>Turn right</td>
            </tr>
            <tr>
                <td>
                <img src={click} />
                </td>
                <td>Click to interact</td>
            </tr>
        </table>
    </>
)

const mobileInstructions = (
    <>
        <table className="ios-instructions-table">
            <tr>
                <td>
                    <img src={mfront} />
                </td>
                <td>
                    <img src={mback} />
                </td>
                <td>
                    <img src={mleft} />
                </td>
                <td>
                    <img src={mright} />
                </td>
                <td colSpan={2}>Click on items to interact</td>
            </tr>
            <tr>
                <td>Move forward</td>
                <td>Move backward</td>
                <td>Turn left</td>
                <td>Turn right</td>
            </tr>
        </table>
    </>
)

const mobileIosInstructions = (
    <>
        <table className="ios-instructions-table">
            <tr>
                <td>
                    <img src={iosfront} />
                </td>
                <td>
                    <img src={iosback} />
                </td>
                <td>
                    <img src={iosleft} />
                </td>
                <td>
                    <img src={iosright} />
                </td>
                <td colSpan={2}>Click on items to interact</td>
            </tr>
            <tr>
                <td>Move forward</td>
                <td>Move backward</td>
                <td>Turn left</td>
                <td>Turn right</td>
            </tr>
        </table>
    </>
)

export const instructions = () => {
    if (window.innerWidth < window.innerHeight) {
        if (isIOS) {
            return mobileIosInstructions
        }
        else {
            return mobileInstructions
        }
    } else {
        return pcInstructions
    }
}