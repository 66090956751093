import { Button, Col, Row } from "reactstrap"
import businesscase from "./../assets/video/business case.mp4"
import iot from "./../assets/video/IoT Applications.mp4"
import security from "./../assets/video/Network & Security.mp4"
import trends from "./../assets/video/Mobile Dev Trends.mp4"
import flutter from "./../assets/video/Flutter.mp4"
import briefing from "./../assets/video/Briefing.mp4"
import { useHistory, useParams } from "react-router-dom"

export default function Watch() {
    const params = useParams()
    const history = useHistory()
    const videos = {
        businesscase,
        iot,
        security,
        trends,
        flutter,
        briefing
    }
    return (
        <>
            <div className="fixed-top">
                <Row>
                    <Col xs={12} className="py-2 px-4">
                        <Button className="btn-round btn-primary" onClick={history.goBack}>Back</Button>
                    </Col>
                </Row>
            </div>
            <div className="theatre">
                <video src={videos[params.name]} controls autoPlay />
            </div>
        </>
    )
}