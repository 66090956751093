import { Vector3 } from "three"
import { playerProps } from "variables/objects"
import { ControlProps } from "variables/objects"

export default function animate(scene, camera, renderer, prevTime, movement, control, effects, interaction) {
	let { enabled, moveForward, moveBackward, moveLeft, moveRight, turnAngle } = movement
    let { velocity, direction } = ControlProps
	requestAnimationFrame(() => animate(scene, camera, renderer, prevTime, movement, control, effects, interaction))
	const time = performance.now()
	const delta = (time - prevTime) / 1000

	effects.forEach(element => {
		if (element.animation == "rotate") {
			element.obj.rotateX(element.value.x)
			element.obj.rotateY(element.value.y)
			element.obj.rotateZ(element.value.z)
		}
		if (element.animation == "position") {
			element.obj.position.x = element.value.x
			element.obj.position.y = element.value.y
			element.obj.position.z = element.value.z
		}
	})
	
	velocity.x -= velocity.x * 10.0 * delta
	velocity.y -= 9.8 * playerProps.mass * delta
	velocity.z -= velocity.z * 10.0 * delta
	let addDist = 0
	let addRotation = 0
	if (window.innerWidth < window.innerHeight) {
		addDist = 4
		addRotation = 0.05
	}
	direction.z = (Number(moveForward) * (0.5 + addDist)) - (Number(moveBackward) * (0.5 + addDist))
	let rotateAngle =  (Number(moveLeft) * (0.05 + addRotation)) - (Number(moveRight) * (0.05 + addRotation))
	if (moveForward || moveBackward) {
		velocity.z -= direction.z * 400.0 * delta
	}
	if (moveLeft || moveRight) {
		camera.rotateY(rotateAngle)
	}
	if (window.innerWidth < window.innerHeight) {
		camera.rotateY(turnAngle / 180 * Math.PI)
	}
	control.moveForward(-velocity.z * delta)
	control.getObject().position.y += velocity.y * delta
	if (control.getObject().position.y < playerProps.height) {
		control.getObject().position.y = playerProps.height
		velocity.y = 0
	}
	movement.moveForward = false
	movement.moveBackward = false
	movement.moveLeft = false
	movement.moveRight = false
	let modelScale = new Vector3
	control.getObject().getWorldPosition(modelScale)
	// console.log(modelScale)
	prevTime = time
	interaction.update()
	renderer.render(scene, camera)
}