import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.2.0";
import "assets/demo/demo.css";

import Lobby from "views/Venue/Lobby";
import Videos from "views/Videos";
import Auditorium from "views/Venue/Auditorium";
import GameRoom from "views/Venue/Game";
import HackRoom from "views/Venue/Hack";
import Watch from "views/Watch";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/problemstatements" component={Videos} />
      <Route path="/auditorium" component={Auditorium} />
      <Route path="/hack" component={HackRoom} />
      <Route path="/game" component={GameRoom} />
      <Route path="/watch/:name" component={Watch} />
      <Route path="/" component={Lobby} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
